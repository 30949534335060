const isValidUsingRegex = (string: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/|feed?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.:,~+@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return Boolean(pattern.test(string));
};

export const isValidUsingUrlConstructor = (string: string): boolean => {
  let url;
  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'feed:';
};

export const isValidRssUrl = (string: string): boolean =>
  isValidUsingUrlConstructor(string) || isValidUsingRegex(string);
